/*TODO*/
/*@import url('//at.alicdn.com/t/font_1280682_ku6o3ceevw9.css');*/
html {
  font-size: 100px;
}
.iconfont {
  vertical-align: middle;
}

div :focus {
  outline: none;
}

.fXedfB {
  background: transparent !important;
}
